
import { useSelectItemWithChildList } from "@/network/lookUp.ts";
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import { DocComponents } from "@/network/officialDoc.ts";
import { reactiveShowSetting } from "@/types/showSetting.ts";
import ShowRelBtn from "@/components/showRelBtn.vue";
import { useRouter, useRoute } from "vue-router";
import roles from "@/types/roles.ts";
import Base from "@/views/layout/Base.vue";
import Pager from "@/views/layout/pager.vue";
import { copy } from "@/network/common";
import {
  useQueryProjectFund,

} from "@/network/historyProject";
import {
  toRef,
  defineComponent,
  inject,
  onMounted,
  onUpdated,
  reactive,
  ref,
  watch,
} from "vue";
import { ElMessageBox } from "element-plus";
export default defineComponent({
  components: {
    ...DocComponents,
    ShowRelBtn,
    Base,
    Pager,
  },
  setup() {
    const cartSetting = reactive({ visible: false });
    const isRefCartBtnAnFlag = ref(false);
    const refCartBtn = ref(null);
    const fundReviewSelectSetting = reactive({ visible: false });
    const fundApplySetting = reactive({
      visible: false,
      id: null,
      project_Code: null,
      project_Name: null,
      projectId: null,
      limit_Money: null,
    });
    const currentSelectId = ref(null);
    const detailSetting = reactiveShowSetting({ review: false });
    const manageSetting = reactiveShowSetting({ review: false });
    const performaceSubmitFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
    });
    const performaceViewFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
      action: "view",
    });
    const docShowSetting = reactive({
      id: null,
      visible: false,
      approve: false,
    });
    const route = useRoute();
    const router = useRouter();
    const user: any = inject("user");
    const cartItems = ref([]);


    const exportExcel = () => {
      query({}, true);
    };
    const onManageChange = () => {
      query();
      //queryCount();
    };



    const onPerformaceScoreReviewSubmit = ({ id }) => {
      query();
    };

    const addOrRevemoToCart = (item) => {
      const index = cartItems.value.indexOf(item);
      isRefCartBtnAnFlag.value = true;
      if (index == -1) {
        cartItems.value.push(item);
      } else {
        cartItems.value.splice(index, 1);
      }
    };

    const showReviewConfirm = (id) => {
      fundReviewSelectSetting.visible = true;
      currentSelectId.value = id;
    };



    const showPerformanceSubmitForm = ({
      performanceScoreId,
      project_Name,
    }) => {
      currentSelectId.value = performanceScoreId;
      performaceSubmitFormSetting.project_Name = project_Name;
      performaceSubmitFormSetting.visible = true;
    };
    const showFundApplyForm = (item) => {
      fundApplySetting.id = item.fundId;
      fundApplySetting.visible = true;
      fundApplySetting.project_Code = item.project_Code;
      fundApplySetting.project_Name = item.project_Name;
      fundApplySetting.projectId = item.id;

      // console.log(item.assessment_Money, item.totalGive_Money);
      fundApplySetting.limit_Money =
        (item.assessment_Money || 0) - (item.totalGive_Money || 0);
    };
    const showPerformanceViewForm = ({ performanceScoreId, project_Name }) => {
      currentSelectId.value = performanceScoreId;
      performaceViewFormSetting.project_Name = project_Name;
      performaceViewFormSetting.visible = true;
      performaceViewFormSetting.action = "review";
    };

    const showApplyForm = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };


    const activeName = ref("all");
    // if (route.query.tab == null) {
    //   if (user.inRoles(roles.prov)) {
    //     activeName.value = "todo";
    //   } else {
    //     activeName.value = "draft";
    //   }
    // }


    const [isLoading, result, query, filter, pager] =
      useQueryProjectFund();
    const resetFilter = copy(filter);
    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const [project_ClassifyDic, project_SubClassifyDic] =
      useSelectItemWithChildList(
        toRef(filter, "project_Classify_Id"),
        toRef(filter, "project_SubClassify_Id"),
        "ProjectClassify",
        false
      );

    const statusDic = [
      { value: 1, label: "草稿" },
      { value: 2, label: "退回" },
      { value: 3, label: "初级审核" },
      { value: 5, label: "省级办理中" },
    ];

    const doQuery = (rFilter) => {
      // if (activeName.value == "draft") cartSetting.visible = true;
      // else {
      //   cartSetting.visible = false;
      // }
      query(rFilter);
      //queryCount();
    };

    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };
    const tabQuery = (v) => {
      cartItems.value.length = 0;
      Object.assign(filter, copy(resetFilter));
      if (v.paneName == "doing" && filter.childStatus == null) {
        filter.childStatus = user.inRoles(roles.prov) ? 12 : 4;
      } else {
        filter.childStatus = null;
      }

      //cartSetting.visible = true;
      doQuery({ status: v.paneName });
    };

    onMounted(() => {
      // if (route.query.action == "add") {
      //   showApplyForm(0);
      //   router.push({ path: route.path, query: {} });
      // }

      if (user.inRoles(roles.city)) {
         resetFilter.city = filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   resetFilter.city = filter.city = user.city_ID;
        //   stop();
        // });
      }

      doQuery({
        status: activeName.value,
        childStatus:
          activeName.value == "doing"
            ? user.inRoles(roles.prov)
              ? 12
              : 4
            : null,
        reviewStatus: 0,
      });
    });
    return {
      showDocContent,
      fundApplySetting,
      showFundApplyForm,
      exportExcel,

      showPerformanceViewForm,
      performaceViewFormSetting,
      showPerformanceSubmitForm,

      onPerformaceScoreReviewSubmit,
      performaceSubmitFormSetting,
      doQuery,
      statusDic,
      project_ClassifyDic,
      project_SubClassifyDic,
      dicCity,
      dicTown,
      cartSetting,
      isRefCartBtnAnFlag,
      refCartBtn,
      cartItems,
      addOrRevemoToCart,
      showReviewConfirm,
      tabQuery,
      showApplyForm,
      docShowSetting,
      detailSetting,
      manageSetting,
      isLoading,

      roles,
      user,
      activeName,
      pager,
      data: result,
      filter,
      fundReviewSelectSetting,
      currentSelectId,
      onManageChange,
      fundCountries: usefundCountriesDic(),
    };
  },
});
