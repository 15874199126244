<template>
  <Base titleIcon="el-icon-s-claim">
  <DocContent :setting="docShowSetting" v-if="docShowSetting.visible"></DocContent>

  <div class="search-row">
    <el-space>
      <!-- <el-switch v-model="filter.isFundCountry" active-text="直管县" inactive-text="市县" v-if="user.inRoles(roles.prov)"
        @change="
          filter.city = filter.town = null;
        doQuery({ pageNum: 1 });
        " />

      <el-select v-model="filter.town" placeholder="直管县" clearable
        v-if="user.inRoles(roles.prov) && filter.isFundCountry">
        <el-option :value="item.id" :label="item.name" :key="item.id" v-for="item in fundCountries"></el-option>
      </el-select> -->

      <el-select v-model="filter.city" placeholder="市级" clearable :disabled="user.inRoles(roles.city)"
        v-show="user.inRoles(roles.prov)" v-if="user.inRoles(roles.city, roles.prov) && !filter.isFundCountry">
        <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
      </el-select>
      <el-select v-model="filter.town" placeholder="区县" clearable
        v-if="user.inRoles(roles.city, roles.prov) && !filter.isFundCountry">
        <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
      </el-select>

      <!-- <el-select v-model="filter.project_Classify_Id" placeholder="请选择项目类型" clearable>
        <el-option v-for="item in project_ClassifyDic" :key="item.value" :value="item.value.toString()"
          :label="item.label"></el-option>
      </el-select>

      <el-select v-model="filter.project_SubClassify_Id" placeholder="请选择项目子类型" clearable>
        <el-option v-for="item in project_SubClassifyDic" :key="item.value" :value="item.value.toString()"
          :label="item.label"></el-option>
      </el-select>

      <el-select v-model="filter.childStatus" placeholder="状态" v-if="activeName == 'all'" clearable>
        <el-option v-for="item in statusDic" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select> -->
      <el-input placeholder="项目编号/项目名称/文保单位" v-model="filter.keyword" style="width: 500px">
        <template #prepend> 关键字 </template>
      </el-input>
      <el-button type="primary" style="margin-left: 5px" @click="doQuery({ pageNum: 1 })">查询</el-button>
    </el-space>
  </div>
  <!-- <el-tabs @tab-click="tabQuery" type="card" v-model="activeName">
      <el-tab-pane
        :label="`草稿(${count.draft})`"
        name="draft"
        v-if="user.inRoles(roles.apply)"
      ></el-tab-pane>
      <el-tab-pane
        v-if="user.inRoles(roles.city)"
        :label="`初级审核(${count.cityReview})`"
        name="cityReview"
      ></el-tab-pane>

      <el-tab-pane
        :label="`待受理(${count.todo})`"
        name="todo"
        v-if="user.inRoles(roles.prov)"
      >
        <div class="search-row">
          <el-input
            placeholder="项目编号/项目名称/文保单位"
            v-model="filter.keyword"
            style="width: 500px"
          >
            <template #prepend> 关键字 </template>
          </el-input>
          <el-button
            type="primary"
            style="margin-left: 5px"
            @click="query({ pageNum: 1 })"
            >查询</el-button
          >
        </div>
      </el-tab-pane>

      <el-tab-pane :label="`办理中(${count.doing})`" name="doing">
        <div>
          <el-select v-model="filter.childStatus" @change="query">
            <el-option label="全部"></el-option>
            <el-option label="省级待受理" :value="4"></el-option>
            <el-option label="省级已受理" :value="12"> </el-option>
            <el-option label="省级办理中" :value="5"></el-option>
            <el-option label="省级已办结" :value="7"></el-option>
          </el-select>
        </div>
        <div v-show="filter.childStatus == 5" style="margin-top: 5px">
          <el-radio-group
            v-model="filter.reviewStatus"
            @change="query"
            size="mini"
          >
            <el-radio-button :label="0">待评审</el-radio-button>
            <el-radio-button :label="1">评审中</el-radio-button>
            <el-radio-button :label="2">评审结束</el-radio-button>
          </el-radio-group>
        </div>
   
      </el-tab-pane>


      <el-tab-pane
        :label="`备选项目(${count.alternativeLib})`"
        name="alternativeLib"
        v-if="user.inRoles(roles.prov)"
      >
      </el-tab-pane>

      <el-tab-pane
        :label="`项目实施(${count.doProject})`"
        name="doProject"
      ></el-tab-pane>
      <el-tab-pane :label="`已终止(${count.end})`" name="end"></el-tab-pane>
      <el-tab-pane :label="`全部项目(${count.all})`" name="all">
        <div class="search-row">
          <el-input
            placeholder="项目编号/项目名称/文保单位"
            v-model="filter.keyword"
            style="width: 500px"
          >
            <template #prepend> 关键字 </template>
          </el-input>
          <el-button
            type="primary"
            style="margin-left: 5px"
            @click="query({ pageNum: 1 })"
            >查询</el-button
          >
        </div>
      </el-tab-pane>
    </el-tabs> -->
  <el-table :data="data.list" v-loading="isLoading">
    <el-table-column label="年份" prop="project_Year"></el-table-column>
    <el-table-column label="地区" prop="areaName">
      <template #default="{ row: item }">
        {{ item.areaName }}
      </template>
    </el-table-column>
    <el-table-column label="项目编号" prop="project_Code"></el-table-column>
    <el-table-column label="项目名称" prop="project_Name" width="400">
      <template #default="{ row: item }">
        <ShowRelBtn :id="item.id" :label="item.project_Name" :showSetting="detailSetting" @link="
          detailSetting.id = item.id;
        detailSetting.visible = true;
        detailSetting.review = false;
        "></ShowRelBtn>
      </template>
    </el-table-column>
    <el-table-column label="项目类型" prop="project_Classify_Name"></el-table-column>
    <el-table-column label="项目子类型" prop="sub_Classify_Name"></el-table-column>


    <el-table-column label="文保单位" prop="project_Unit_Name">
      <template #default="{ row: item }">
        <a href="javascript:void()"> {{ item.project_Unit_Name }}</a>
      </template>
    </el-table-column>
    <!-- <el-table-column label="级别" prop="heritageUnit_LevelName">
    </el-table-column> -->
    <!-- <el-table-column label="批文" width="200" >
        <template #default="{ row: item }">
          <template v-if="item.setupDoc_Id != null">
            计划书：<DocViewBtn
              :doc="{
                id: item.setupDoc_Id,
                doc_Compile_No: item.setupDoc_Name,
              }"
              @show="showDocContent(item.setupDoc_Id)"
            ></DocViewBtn>
          </template>
          <template v-if="item.proposalDoc_Id != null">
            方案：<DocViewBtn
              :doc="{
                id: item.proposalDoc_Id,
                doc_Compile_No: item.proposalDoc_Name,
              }"
              @show="showDocContent(item.proposalDoc_Id)"
            ></DocViewBtn>
          </template>
          <template v-else>
            <i class="el-icon-warning Red"></i> {{ item.noDoc_Note }}
          </template>
        </template>
      </el-table-column> -->
    <!-- 
      <el-table-column
        label="方案设计单位"
        prop="planDesignUnit"
        
      >
      </el-table-column> -->
    <el-table-column label="项目总概算" prop="budget_Total"></el-table-column>
    <el-table-column label="初始申请补助" prop="budget_Apply_Count" v-if="
      activeName == 'draft' ||
      activeName == 'cityReview' ||
      activeName == 'todo'
    "></el-table-column>
    <el-table-column v-if="activeName == 'todo'" label="终审金额" prop="assessment_Money"></el-table-column>
    <el-table-column label="预算控制数" prop="assessment_Money" v-if="
      activeName == 'alternativeLib' ||
      activeName == 'fundApply' ||
      activeName == 'all'
    "></el-table-column>
    <el-table-column label="累计安排金额" prop="totalGive_Money" v-if="activeName == 'all'"></el-table-column>
    <el-table-column v-if="activeName == 'fundApply'" label="申请金额（万元）" prop="applyAmount"></el-table-column>
    <el-table-column label="项目状态" prop="status_Text" v-if="
      activeName != 'alternativeLib' &&
      activeName != 'fundApply' &&
      activeName != 'performance'
    "></el-table-column>

  </el-table>
  <Pager :pager="pager" @change="doQuery"></Pager>

  </Base>
</template>

<script lang="ts">
import { useSelectItemWithChildList } from "@/network/lookUp.ts";
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import { DocComponents } from "@/network/officialDoc.ts";
import { reactiveShowSetting } from "@/types/showSetting.ts";
import ShowRelBtn from "@/components/showRelBtn.vue";
import { useRouter, useRoute } from "vue-router";
import roles from "@/types/roles.ts";
import Base from "@/views/layout/Base.vue";
import Pager from "@/views/layout/pager.vue";
import { copy } from "@/network/common";
import {
  useQueryProjectFund,

} from "@/network/historyProject";
import {
  toRef,
  defineComponent,
  inject,
  onMounted,
  onUpdated,
  reactive,
  ref,
  watch,
} from "vue";
import { ElMessageBox } from "element-plus";
export default defineComponent({
  components: {
    ...DocComponents,
    ShowRelBtn,
    Base,
    Pager,
  },
  setup() {
    const cartSetting = reactive({ visible: false });
    const isRefCartBtnAnFlag = ref(false);
    const refCartBtn = ref(null);
    const fundReviewSelectSetting = reactive({ visible: false });
    const fundApplySetting = reactive({
      visible: false,
      id: null,
      project_Code: null,
      project_Name: null,
      projectId: null,
      limit_Money: null,
    });
    const currentSelectId = ref(null);
    const detailSetting = reactiveShowSetting({ review: false });
    const manageSetting = reactiveShowSetting({ review: false });
    const performaceSubmitFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
    });
    const performaceViewFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
      action: "view",
    });
    const docShowSetting = reactive({
      id: null,
      visible: false,
      approve: false,
    });
    const route = useRoute();
    const router = useRouter();
    const user: any = inject("user");
    const cartItems = ref([]);


    const exportExcel = () => {
      query({}, true);
    };
    const onManageChange = () => {
      query();
      //queryCount();
    };



    const onPerformaceScoreReviewSubmit = ({ id }) => {
      query();
    };

    const addOrRevemoToCart = (item) => {
      const index = cartItems.value.indexOf(item);
      isRefCartBtnAnFlag.value = true;
      if (index == -1) {
        cartItems.value.push(item);
      } else {
        cartItems.value.splice(index, 1);
      }
    };

    const showReviewConfirm = (id) => {
      fundReviewSelectSetting.visible = true;
      currentSelectId.value = id;
    };



    const showPerformanceSubmitForm = ({
      performanceScoreId,
      project_Name,
    }) => {
      currentSelectId.value = performanceScoreId;
      performaceSubmitFormSetting.project_Name = project_Name;
      performaceSubmitFormSetting.visible = true;
    };
    const showFundApplyForm = (item) => {
      fundApplySetting.id = item.fundId;
      fundApplySetting.visible = true;
      fundApplySetting.project_Code = item.project_Code;
      fundApplySetting.project_Name = item.project_Name;
      fundApplySetting.projectId = item.id;

      // console.log(item.assessment_Money, item.totalGive_Money);
      fundApplySetting.limit_Money =
        (item.assessment_Money || 0) - (item.totalGive_Money || 0);
    };
    const showPerformanceViewForm = ({ performanceScoreId, project_Name }) => {
      currentSelectId.value = performanceScoreId;
      performaceViewFormSetting.project_Name = project_Name;
      performaceViewFormSetting.visible = true;
      performaceViewFormSetting.action = "review";
    };

    const showApplyForm = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };


    const activeName = ref("all");
    // if (route.query.tab == null) {
    //   if (user.inRoles(roles.prov)) {
    //     activeName.value = "todo";
    //   } else {
    //     activeName.value = "draft";
    //   }
    // }


    const [isLoading, result, query, filter, pager] =
      useQueryProjectFund();
    const resetFilter = copy(filter);
    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const [project_ClassifyDic, project_SubClassifyDic] =
      useSelectItemWithChildList(
        toRef(filter, "project_Classify_Id"),
        toRef(filter, "project_SubClassify_Id"),
        "ProjectClassify",
        false
      );

    const statusDic = [
      { value: 1, label: "草稿" },
      { value: 2, label: "退回" },
      { value: 3, label: "初级审核" },
      { value: 5, label: "省级办理中" },
    ];

    const doQuery = (rFilter) => {
      // if (activeName.value == "draft") cartSetting.visible = true;
      // else {
      //   cartSetting.visible = false;
      // }
      query(rFilter);
      //queryCount();
    };

    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };
    const tabQuery = (v) => {
      cartItems.value.length = 0;
      Object.assign(filter, copy(resetFilter));
      if (v.paneName == "doing" && filter.childStatus == null) {
        filter.childStatus = user.inRoles(roles.prov) ? 12 : 4;
      } else {
        filter.childStatus = null;
      }

      //cartSetting.visible = true;
      doQuery({ status: v.paneName });
    };

    onMounted(() => {
      // if (route.query.action == "add") {
      //   showApplyForm(0);
      //   router.push({ path: route.path, query: {} });
      // }

      if (user.inRoles(roles.city)) {
         resetFilter.city = filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   resetFilter.city = filter.city = user.city_ID;
        //   stop();
        // });
      }

      doQuery({
        status: activeName.value,
        childStatus:
          activeName.value == "doing"
            ? user.inRoles(roles.prov)
              ? 12
              : 4
            : null,
        reviewStatus: 0,
      });
    });
    return {
      showDocContent,
      fundApplySetting,
      showFundApplyForm,
      exportExcel,

      showPerformanceViewForm,
      performaceViewFormSetting,
      showPerformanceSubmitForm,

      onPerformaceScoreReviewSubmit,
      performaceSubmitFormSetting,
      doQuery,
      statusDic,
      project_ClassifyDic,
      project_SubClassifyDic,
      dicCity,
      dicTown,
      cartSetting,
      isRefCartBtnAnFlag,
      refCartBtn,
      cartItems,
      addOrRevemoToCart,
      showReviewConfirm,
      tabQuery,
      showApplyForm,
      docShowSetting,
      detailSetting,
      manageSetting,
      isLoading,

      roles,
      user,
      activeName,
      pager,
      data: result,
      filter,
      fundReviewSelectSetting,
      currentSelectId,
      onManageChange,
      fundCountries: usefundCountriesDic(),
    };
  },
});
</script>

<style lang="scss" >
.backConfirmForm {
  textarea {
    height: 150px;
  }
}

.an-shark {
  animation: shakeFrames 0.8s 1 ease-in;
}

@keyframes shakeFrames {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}
</style>