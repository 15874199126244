import request from "@/utils/request";
import axios from "axios";
import { ref, Ref, reactive, onMounted, toRef, watch, isReactive } from "vue";
import { ElMessage } from "element-plus";
import { copyFrom } from "./common";
import { openWindowWithToken } from "@/utils/util";

export function useQueryProjectFund(initFilter = null): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);
  const filter = reactive({
    pageNum: 1,
    pageSize: 20,
    status: null,
    childStatus: null,
    reviewStatus: null,
    keyword: null,
    city: null,
    town: null,
    project_Classify_Id: null,
    project_SubClassify_Id: null,
    isFundCountry: false,
  });
  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });
  function query(replaceFilter = null, isExport = false) {
    isLoading.value = true;
    if (replaceFilter != null) Object.assign(filter, replaceFilter);
    request
      .get(`/historyProject/query`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query, filter, pager];
}
